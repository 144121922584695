import React, { useState, useRef, useEffect } from 'react';
import './Tags.css';
import './Dining.css';
import { Link } from "react-router-dom";
import './Searchbar.css';
import {BASEURL} from './Baseurl/Baseurl';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRocket,faSearch,faHome,faEllipsisVertical,faGift} from "@fortawesome/free-solid-svg-icons";
 

function Tags() {
  const [showModal, setShowModal] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [searchTag, setSearchTag] = useState('');
  const [images, setImages] = useState([]);
  const [tags, setTags] = useState([]);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [filteredTags, setFilteredTags] = useState([]);
  const downloadLinkRef = useRef(null);

  const fetchTags = async () => {
    try {
      const response = await fetch(`${BASEURL}branding/creator_image/all_tags`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.Response.Success === '1') {
        setTags(data.Response.Result);
      } else {
        console.error(data.Response.Message);
      }
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

 
  const handleSearch = async () => {
    try {
      const response = await fetch(`${BASEURL}branding/creator_image/tagsearch?tags=${searchTag.toLowerCase()}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.Response.Success === '1') {
        setImages(data.Response.Result);
      } else {
        setImages([]);
        console.error(data.Response.Message);
      }
    } catch (error) {
      console.error('Error fetching images:', error);
      setImages([]);
    }
  };


  const handleDownload = async (imgUrl) => {
    try {
      const blob = await fetch(imgUrl).then((r) => r.blob());
      const blobUrl = window.URL.createObjectURL(blob);

      if (downloadLinkRef.current) {
        downloadLinkRef.current.href = blobUrl;
        downloadLinkRef.current.setAttribute('download', `image_${new Date().getTime()}.jpg`);
        downloadLinkRef.current.click();
        window.URL.revokeObjectURL(blobUrl);
      }
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };


  const handleTagSelect = (tag) => {
    setSearchTag(tag);
    handleSearch();
  };


  useEffect(() => {
    if (searchTag.length > 0) {
      const filtered = tags.filter(tag => tag.toLowerCase().includes(searchTag.toLowerCase()));
      setFilteredTags(filtered);
    } else {
      setFilteredTags([]);
    }
  }, [searchTag, tags]);

  return (
    <div className='col-lg-12 row container'>
      <div className='col-lg-1'></div>
      <div className='col-lg-10'>
        <div className="container-fluid bg-primary" 
          style={{ 
            height: '30vh', 
            backgroundImage: 'url("https://images.pexels.com/photos/23228141/pexels-photo-23228141/free-photo-of-cityscape-of-istanbul-in-evening.jpeg?auto=compress&cs=tinysrgb&w=800")', 
            backgroundSize: 'cover', 
            backgroundPosition: 'center', 
            backgroundRepeat: 'no-repeat',
          }}>
          <div className="row">
            <div className="col-lg-12 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <Link to="/">
                  <FontAwesomeIcon className="homevou mt-3" icon={faHome} />
                </Link>
              </div>
              <div className="d-flex align-items-center mt-3">
                <FontAwesomeIcon className="text-dark mt-3" icon={faEllipsisVertical} />
                <h5 className="mt-1 ml-2 gifticon2"> |</h5>
                <button className="stavou ml-5 d-flex">
                  <FontAwesomeIcon className="p-3 ml-2 gifticon" icon={faGift} />
                  <h6 className="jik mt-2 ml-3">
                    <Link to="/Custom" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <u style={{ marginTop: "4px", display: "inline-block" }}>Custom Template</u>
                    </Link>
                  </h6>
                </button>
              </div>
            </div>
          </div>

          <div className="col-lg-12 search-container">
            <center><span className="custom-text">
              Craft stunning designs, quicker!
            </span></center> 
          </div>

          <div className="col-lg-12 search-container">
            <center><span className="custom-text">
              The best free stock photos, royalty free images shared by creators
            </span></center> 
          </div>

          <div className="col-lg-12 search-container">
            <input
              type="text"
              className="search-input"
              value={searchTag}
              onChange={(e) => setSearchTag(e.target.value)}
              placeholder="Search by tag..."
            />
            <button className="search-button" onClick={handleSearch}>
              <FontAwesomeIcon className="text-white" icon={faSearch} />
            </button>
            <div className="suggestions-container">
              {filteredTags.map((tag, index) => (
                <div key={index} className="suggestion-item" onClick={() => handleTagSelect(tag)}>
                  {tag}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="row justify-content-left mt-3">
          {images.length > 0 ? (
            images.map((image, index) => (
              <div
                key={image.id}
                className="col-lg-4 mb-4"
                style={{ height: "500px" }}
              >
                <div
                  className="image-card position-relative"
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                >
                  <img src={image.img} alt={image.tags.join(', ')} className="image" style={{ width: "100%", height: "500px", objectFit: "cover" }} />
                  {hoverIndex === index && (
                    <div className="overlay position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center">
                      <div className="download-icon position-absolute" style={{ bottom: '10px', left: '10px', color: 'white' }}>
                        <img src={image.user_details.sb_users_profile_img} alt={image.user_details.sb_users_name} className="rounded-circle" style={{ width: '40px', height: '40px', objectFit: 'cover', border: '2px solid white' }} />
                        <span className="download-count"> {image.user_details.sb_users_name}</span>
                      </div>
                      <div className="creator-info position-absolute" style={{ bottom: '10px', right: '10px', textAlign: 'right', color: 'white' }}>
                        <i className="fas fa-download" onClick={() => handleDownload(image.img)}></i>
                        <span className="download-count">{image.downloadCount}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <span className="custom-text">
              <FontAwesomeIcon icon={faRocket} /> Make eye-catching designs, efficiently!
            </span>
          )}
        </div>
      </div>
      <div className='col-lg-1'></div>
      <a href="/" ref={downloadLinkRef} style={{ display: 'none' }}></a>
    </div>
  );
}

export default Tags;



