

// import React, { useState, useEffect } from 'react';
// import { Button, Card, CardMedia, Grid, Typography } from '@mui/material';
// import axios from 'axios';
// import Swal from 'sweetalert2';
// import { BASEURL1 } from './Baseurl/Baseurl';
// import { useNavigate } from 'react-router-dom';

// export const Creators = () => {
//   const [selectedImages, setSelectedImages] = useState([]);
//   const userId = localStorage.getItem("userId")
 

//   useEffect(() => {
//     fetchImages();
//   }, []);

//   const fetchImages = async () => {
//     try {
//       const response = await axios.get(`${BASEURL1}creatorImg/get?uId=${userId}`);
//       const details = response.data.Response.Result;

//       if (Array.isArray(details)) {
//         const images = details.flatMap(item =>
//           item.sb_creator_images_file.map(file => ({
//             ...file,
//             sb_creator_images_id: item.sb_creator_images_id,
//             sb_creator_images_uid: item.sb_creator_images_uid,
//             sb_creator_images_visible: item.sb_creator_images_visible,
//           }))
//         );
//         setSelectedImages(images);
//       } else {
//         setSelectedImages([]);
//       }
//     } catch (error) {
//       console.error('Error fetching images:', error);
//       Swal.fire({ icon: 'error', title: 'Error', text: 'Unable to fetch images' });
//     }
//   };


//   const handleImageStatusUpdate = async (imgId, newStatus, uId, fileId) => {
//     try {
//       console.log("Updating Image ID:", imgId);
//       const response = await axios.post(`${BASEURL1}creator/imgVisible/sts`, {
//         imgId,
//         sts: newStatus,
//         uId,
//         fileId,
//       });
//       if (response.data.Response.Success === '1') {
//         fetchImages();
//         Swal.fire({ icon: 'success', title: 'Success', text: 'Status updated successfully!' });
//       } else {
//         throw new Error('Unable to update status');
//       }
//     } catch (error) {
//       Swal.fire({ icon: 'error', title: 'Error', text: 'Unable to update status' });
//     }
//   };

//   return (
//     <div className="container-fluid mt-3 text-center">
    
//       <Typography variant="h5" gutterBottom>
//         Showcase Your Masterpieces – Manage Your Posts Here!
//       </Typography>
//       <Grid container spacing={2} justifyContent="center" sx={{ px: { lg: 4 } }}>
//         {selectedImages.length > 0 ? (
//           selectedImages.map((image, index) => (
//             <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
//               <Card sx={{ padding: 2, textAlign: 'center' }}>
//                 <CardMedia
//                   component="img"
//                   height="150"
//                   image={image.img}
//                   alt="User Image"
//                   style={{ objectFit: 'cover' }}
//                 />
//                 <Typography variant="body2">Download Count: {image.downloadCount}</Typography>
//                 <Button
//                   variant={image.sts == '1' ? 'contained' : 'outlined'}
//                   color={image.sts == '1' ? 'success' : 'error'}
//                   onClick={() => handleImageStatusUpdate(image.sb_creator_images_id, image.sts == '1' ? '0' : '1', image.sb_creator_images_uid, image.id)}
//                 >
//                   {image.sts == '1' ? 'Active' : 'Inactive'}
//                 </Button>
//               </Card>
//             </Grid>
//           ))
//         ) : (
//           <Typography variant="body1">No images available.</Typography>
//         )}
//       </Grid>
//     </div>
//   );
// };

import React, { useState, useEffect } from 'react';
import { Button, Card, CardMedia, Grid, Typography, Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BASEURL } from './Baseurl/Baseurl';

export const Creators = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [dashboardData, setDashboardData] = useState(null);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    fetchDashboardData();
    fetchImages();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const response = await axios.get(`${BASEURL}branding/admin/Dash_rec?uid=${userId}`);
      if (response.data.Response.Success === "1") {
        setDashboardData(response.data.Response.Result);
      } else {
        throw new Error("Failed to fetch dashboard data");
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      Swal.fire({ icon: 'error', title: 'Error', text: 'Unable to fetch dashboard data' });
    }
  };

  const fetchImages = async () => {
    try {
      const response = await axios.get(`${BASEURL}branding/admin/creatorImg/get?uId=${userId}`);
      const details = response.data.Response.Result;

      if (Array.isArray(details)) {
        const images = details.flatMap(item =>
          item.sb_creator_images_file.map(file => ({
            ...file,
            sb_creator_images_id: item.sb_creator_images_id,
            sb_creator_images_uid: item.sb_creator_images_uid,
            sb_creator_images_visible: item.sb_creator_images_visible,
          }))
        );
        setSelectedImages(images);
      } else {
        setSelectedImages([]);
      }
    } catch (error) {
      console.error('Error fetching images:', error);
      Swal.fire({ icon: 'error', title: 'Error', text: 'Unable to fetch images' });
    }
  };

  const handleImageStatusUpdate = async (imgId, newStatus, uId, fileId) => {
    try {
      console.log("Updating Image ID:", imgId);
      const response = await axios.post(`${BASEURL}creator/imgVisible/sts`, {
        imgId,
        sts: newStatus,
        uId,
        fileId,
      });
      if (response.data.Response.Success === '1') {
        fetchImages();
        Swal.fire({ icon: 'success', title: 'Success', text: 'Status updated successfully!' });
      } else {
        throw new Error('Unable to update status');
      }
    } catch (error) {
      Swal.fire({ icon: 'error', title: 'Error', text: 'Unable to update status' });
    }
  };

  return (
    <Box className="container-fluid mt-3 text-center">
      <Typography variant="h5" gutterBottom fontWeight="bold">
        Showcase Your Masterpieces – Manage Your Posts Here!
      </Typography>

      {/* Dashboard Stats Cards */}
      <Grid container spacing={3} justifyContent="center"  sx={{ px: { lg: 4 } }}>
        {dashboardData ? (
          <>
            {[
              { label: "Total Images", value: dashboardData.totalImages, color: "#A8D5BA" },
              { label: "Total Downloads", value: dashboardData.totalDownloads, color: "#A0C4FF" },
              { label: "Wallet", value: dashboardData.wallet, color: "#FFCF91" }
            ].map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
          
<Card
  sx={{
    height: "100%",  // Ensures all cards have the same height
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 3,
    textAlign: "center",
    backgroundColor: "transparent",  // No background color initially
    color: "#333",
    borderRadius: "12px",
    border: "1px solid #ddd", // Light border to define the card
    transition: "transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease",
    "&:hover": { 
      transform: "scale(1.05)", 
      boxShadow: "0 6px 15px rgba(0,0,0,0.2)",
      backgroundColor: item.color, // Apply background color on hover
      color: "black" // Change text color on hover for contrast
    }
  }}
>
  <Typography variant="h6" fontWeight="bold">{item.label}</Typography>
  <Typography variant="h4" fontWeight="bold">{item.value}</Typography>
  
  {item.label === "Total Downloads" && (
  <Typography 
    variant="body2" 
    sx={{ mt: "auto", fontStyle: "italic", color: item.value >= 100 ? "#D2691E" : "#888" }}
  >
    {item.value >= 100 
      ? "Congrats! Your downloads reached 100. Send a request and enjoy your earnings!" 
      : "Keep going! Reach 100 downloads to unlock your earnings."}
  </Typography>
)}

</Card>



             </Grid>





            ))}
          </>
        ) : (
          <CircularProgress />
        )}
      </Grid>

      {/* Images Gallery */}
      {/* <Grid container spacing={3} justifyContent="center">
        {selectedImages.length > 0 ? (
          selectedImages.map((image, index) => (
            <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
              <Card 
                sx={{ 
                  padding: 2, 
                  textAlign: 'center', 
                  border: "1px solid #ccc",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": { transform: "scale(1.05)", boxShadow: "0 6px 15px rgba(0,0,0,0.2)" } 
                }}
              >
                <CardMedia
                  component="img"
                  height="160"
                  image={image.img}
                  alt="User Image"
                  sx={{ objectFit: "cover", borderRadius: "8px" }}
                />
                <Typography variant="body2" sx={{ mt: 1, fontWeight: "bold" }}>
                  Download Count: {image.downloadCount}
                </Typography>
                <Button
                  sx={{ 
                    mt: 1, 
                    border: "2px solid", 
                    borderColor: image.sts === '1' ? '#8BC34A' : '#E57373',
                    color: image.sts === '1' ? '#8BC34A' : '#E57373',
                    "&:hover": { 
                      backgroundColor: image.sts === '1' ? '#8BC34A' : '#E57373', 
                      color: "white" 
                    } 
                  }}
                  onClick={() => handleImageStatusUpdate(image.sb_creator_images_id, image.sts === '1' ? '0' : '1', image.sb_creator_images_uid, image.id)}
                >
                  {image.sts === '1' ? 'Active' : 'Inactive'}
                </Button>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body1" sx={{ mt: 3 }}>No images available.</Typography>
        )}
      </Grid> */}


     <Grid container spacing={2} justifyContent="center" className='mt-2' sx={{ px: { lg: 4 } }}>
      {selectedImages.length > 0 ? (
         selectedImages.map((image, index) => (
           <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
             <Card sx={{ padding: 2, textAlign: 'center' }}>
                <CardMedia
                 component="img"
                  height="150"
                 image={image.img}
                  alt="User Image"
                 style={{ objectFit: 'cover' }}
               />
               <Typography variant="body2">Download Count: {image.downloadCount}</Typography>
               <Button
                 variant={image.sts == '1' ? 'contained' : 'outlined'}
                  color={image.sts == '1' ? 'success' : 'error'}
                 onClick={() => handleImageStatusUpdate(image.sb_creator_images_id, image.sts == '1' ? '0' : '1', image.sb_creator_images_uid, image.id)}
                >
                  {image.sts == '1' ? 'Active' : 'Inactive'}
               </Button>
              </Card>
           </Grid>
         ))
       ) : (
          <Typography variant="body1">No images available.</Typography>
        )}
     </Grid> 
    </Box>
  );
};


// import React, { useState, useEffect } from 'react';
// import { Button, Card, CardMedia, Grid, Typography, Box, CircularProgress } from '@mui/material';
// import axios from 'axios';
// import Swal from 'sweetalert2';
// import { BASEURL } from './Baseurl/Baseurl';

// export const Creators = () => {
//   const [selectedImages, setSelectedImages] = useState([]);
//   const [dashboardData, setDashboardData] = useState(null);
//   const userId = localStorage.getItem("userId");

//   useEffect(() => {
//     fetchDashboardData();
//     fetchImages();
//   }, []);

//   const fetchDashboardData = async () => {
//     try {
//       const response = await axios.get(`${BASEURL}branding/admin/Dash_rec?uid=${userId}`);
//       if (response.data.Response.Success === "1") {
//         setDashboardData(response.data.Response.Result);
//       } else {
//         throw new Error("Failed to fetch dashboard data");
//       }
//     } catch (error) {
//       console.error("Error fetching dashboard data:", error);
//       Swal.fire({ icon: 'error', title: 'Error', text: 'Unable to fetch dashboard data' });
//     }
//   };

//   const fetchImages = async () => {
//     try {
//       const response = await axios.get(`${BASEURL}branding/admin/creatorImg/get?uId=${userId}`);
//       const details = response.data.Response.Result;

//       if (Array.isArray(details)) {
//         const images = details.flatMap(item =>
//           item.sb_creator_images_file.map(file => ({
//             ...file,
//             sb_creator_images_id: item.sb_creator_images_id,
//             sb_creator_images_uid: item.sb_creator_images_uid,
//             sb_creator_images_visible: item.sb_creator_images_visible,
//           }))
//         );
//         setSelectedImages(images);
//       } else {
//         setSelectedImages([]);
//       }
//     } catch (error) {
//       console.error('Error fetching images:', error);
//       Swal.fire({ icon: 'error', title: 'Error', text: 'Unable to fetch images' });
//     }
//   };

//   const handleImageStatusUpdate = async (imgId, newStatus, uId, fileId) => {
//     try {
//       console.log("Updating Image ID:", imgId);
//       const response = await axios.post(`${BASEURL}/branding/creator/imgVisible/sts`, {
//         imgId,
//         sts: newStatus,
//         uId,
//         fileId,
//       });
//       if (response.data.Response.Success === '1') {
//         fetchImages();
//         Swal.fire({ icon: 'success', title: 'Success', text: 'Status updated successfully!' });
//       } else {
//         throw new Error('Unable to update status');
//       }
//     } catch (error) {
//       Swal.fire({ icon: 'error', title: 'Error', text: 'Unable to update status' });
//     }
//   };

//   return (
//     <Box className="container-fluid mt-3 text-center">
//       <Typography variant="h5" gutterBottom fontWeight="bold">
//         Showcase Your Masterpieces – Manage Your Posts Here!
//       </Typography>

//       <Grid container spacing={3} justifyContent="center" sx={{ my: 2 }}>
//         {dashboardData ? (
//           <>
//             {[
//               { label: "Total Images", value: dashboardData.totalImages, color: "#4CAF50" },
//               { label: "Total Downloads", value: dashboardData.totalDownloads, color: "#2196F3" },
//               { label: "Wallet", value: dashboardData.wallet, color: "#FF9800" }
//             ].map((item, index) => (
//               <Grid item xs={12} sm={6} md={4} key={index}>
//                 <Card
//                   sx={{
//                     padding: 3,
//                     textAlign: 'center',
//                     backgroundColor: item.color,
//                     color: "white",
//                     borderRadius: "12px",
//                     transition: "transform 0.3s ease, box-shadow 0.3s ease",
//                     "&:hover": { transform: "scale(1.05)", boxShadow: "0 6px 15px rgba(0,0,0,0.2)" }
//                   }}
//                 >
//                   <Typography variant="h6" fontWeight="bold">{item.label}</Typography>
//                   <Typography variant="h4" fontWeight="bold">{item.value}</Typography>
//                 </Card>
//               </Grid>
//             ))}
//           </>
//         ) : (
//           <CircularProgress />
//         )}
//       </Grid>

//       {/* Images Gallery */}
//       <Grid container spacing={3} justifyContent="center">
//         {selectedImages.length > 0 ? (
//           selectedImages.map((image, index) => (
//             <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
//               <Card 
//                 sx={{ 
//                   padding: 2, 
//                   textAlign: 'center', 
//                   transition: "transform 0.3s ease, box-shadow 0.3s ease",
//                   "&:hover": { transform: "scale(1.05)", boxShadow: "0 6px 15px rgba(0,0,0,0.2)" } 
//                 }}
//               >
//                 <CardMedia
//                   component="img"
//                   height="160"
//                   image={image.img}
//                   alt="User Image"
//                   sx={{ objectFit: "cover", borderRadius: "8px" }}
//                 />
//                 <Typography variant="body2" sx={{ mt: 1, fontWeight: "bold" }}>
//                   Download Count: {image.downloadCount}
//                 </Typography>
//                 <Button
//                   sx={{ mt: 1 }}
//                   variant={image.sts === '1' ? 'contained' : 'outlined'}
//                   color={image.sts === '1' ? 'success' : 'error'}
//                   onClick={() => handleImageStatusUpdate(image.sb_creator_images_id, image.sts === '1' ? '0' : '1', image.sb_creator_images_uid, image.id)}
//                 >
//                   {image.sts === '1' ? 'Active' : 'Inactive'}
//                 </Button>
//               </Card>
//             </Grid>
//           ))
//         ) : (
//           <Typography variant="body1" sx={{ mt: 3 }}>No images available.</Typography>
//         )}
//       </Grid>
//     </Box>
//   );
// };
