


// const BASEURL = 'https://sparkredeem.com/sparky/';


// const BASEURL = 'http://localhost:3009/';

const BASEURL ='https://sparkpik.com/piky/'
const BASEURL1 ='https://sparkpik.com/piky/branding/admin/'
// const BASEURL1 = 'https://sparkredeem.com/sparky/branding/admin/';




export { BASEURL, BASEURL1 };